input,
button,
textarea {
    border-radius: 0 !important;
}

button:disabled {
    cursor: not-allowed;
}

a {
    cursor: pointer;
}

.underline-text {
    text-decoration: underline;
}

p a,
.fnbo-inline-link {
    color: currentcolor;
    text-decoration: underline;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(171 171 171 / 24%) !important;
}

// default
.fnbo-primary-color {
    color: #000;
}

.web-hidden {
    display: none !important;
}

.fnbo-display-flex {
    display: flex;
}

.fnbo-flex-align-right {
    margin-left: auto;
}

.fnbo-flex-align-left {
    margin-right: auto;
}


.fnbo-menu-horizontal-line {
    height: 1px;
    display: block;
    background-color: #000;
    opacity: 0.1;
}

.fnbo-center-content {
    margin-left: 160px;
    margin-right: 160px;
}

.fnbo-horizontal-center-items-flex {
    display: flex;
    align-items: center;
}

.ngx-pagination {
    padding: 0;
}

.mat-mdc-form-field-subscript-wrapper,
.mdc-line-ripple {
    display: none;
}

a:focus,
a,
button:focus,
button {
    outline: none;
    text-decoration: none;
}

.fnbo-search-opt-highlight {
    font-weight: 600;
}

.fnbo-space-below-0 {
    margin-bottom: 0px !important;
}

.fnbo-space-below {
    margin-bottom: 20px;
}

.fnbo-space-above {
    margin-top: 20px;
}

.fnbo-space-left {
    margin-left: 20px;
}


.fnbo-small-note {
    font-size: var(--secondary-font-size);
}

#fnbo-search-dialog-area * .mdc-notched-outline>* {
    border-color: #fff !important;
    border-radius: 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

}

#fnbo-sr-pagination * .ngx-pagination .current,
#fnbo-products-pagination * .ngx-pagination .current {
    background: #000;
}



.fnbo-sr-filter-items mat-form-field,
.fnbo-sr-filter-items .mat-mdc-floating-label,
.fnbo-products-filter-items mat-form-field,
.fnbo-products-filter-items .mat-mdc-floating-label {

    font-size: var(--mdc-typography-body1-font-size, 12px);
}

.fnbo-sr-filter-items * .mat-mdc-select,
.fnbo-products-filter-items * .mat-mdc-select {
    font-size: var(--mdc-typography-body1-font-size, 12px);

}

.fnbo-sr-mat-panel * .mat-mdc-option,
.fnbo-product-mat-panel * .mat-mdc-option {
    min-height: 40px !important;
    font-size: var(--mdc-typography-body1-font-size, 14px);

}

.fnbo-sr-filter-items * .mat-mdc-form-field-infix,
.fnbo-products-filter-items * .mat-mdc-form-field-infix {
    min-height: 50px;
}


.fnbo-sr-filter-items * .mdc-text-field--filled,
.fnbo-products-filter-items * .mdc-text-field--filled {
    background-color: white;
    border: 1px solid;

    border-left: none;
    border-radius: 0;

}

.fnbo-sr-filter-items * .mdc-floating-label,
.fnbo-products-filter-items * .mdc-floating-label {
    opacity: 0.6;
}

.fnbo-sr-filter-items:first-of-type * .mdc-text-field--filled,
.fnbo-products-filter-items:first-of-type * .mdc-text-field--filled {
    border: 1px solid;

}

.drop-down-menu {
    max-height: 40px !important;
}

.drop-down-menu * .mdc-floating-label {
    top: 15px !important;
}

.drop-down-menu * .mat-mdc-form-field-infix {
    padding-top: 10px !important;
}

.mdc-text-field {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-radius: 0 !important;
}

.mat-mdc-select-value-text {
    font-size: var(--secondary-font-size) !important;
}

.fnbo-tnc-dialog-header h1,
.fnbo-bcf-dialog-header h1,
.fnbo-fca-r-dialog-header h1 {
    font-weight: 600;
    font-size: var(--xlarge-font-size, 30px);
    line-height: 2.375rem;
    text-align: center;
}

.fnbo-tnc-dialog * h6,
.fnbo-bcf-dialog-header * h6 {
    font-weight: 600;
    color: #333333;
}


.fnbo-terms-condition-dialog * .mat-mdc-dialog-surface {
    border-radius: 0 !important;
}


.fnbo-autor-optin-sect * .mdc-checkbox__background:checked {
    border-color: var(--mdc-checkbox-unselected-icon-color, #000) !important;
    background-color: var(--mdc-checkbox-selected-icon-color, #000) !important;

}

.fnbo-autor-optin-sect * .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: transparent !important;

}

.fnbo-autor-optin-sect * .mdc-checkbox__ripple,
.mdc-checkbox__ripple {
    display: none;
    // auto redeem css  ends
}

//partial payment
.fnbo-cart-comb-check * .mdc-checkbox__background {
    border-color: #000 !important;
}

.fnbo-cart-comb-check * .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: #000 !important;
    color: var(--mdc-theme-text-primary-on-background, #fff) !important;
}

//partial payment ends

// btn styling
.mat-mdc-button[disabled][disabled] {
    cursor: not-allowed;
    pointer-events: all;
}

// btn styling ends

//address checkbox
#fnbo-cart-left-area.fnbo-primary-bg * .fnbo-cart-addy-check * .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: transparent !important;
    color: var(--mdc-theme-text-primary-on-background, #fff) !important;
}

#fnbo-cart-left-area.fnbo-primary-bg * .fnbo-cart-addy-check * .mdc-checkbox__background {
    border-color: #fff !important;
}

#fnbo-cart-right-area * .fnbo-cart-addy-check * .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: #000 !important;
    color: var(--mdc-theme-text-primary-on-background, #fff) !important;
}

#fnbo-cart-right-area * .fnbo-cart-addy-check * .mdc-checkbox__background {
    border-color: #000 !important;
}

//address checkbox ends

//dialog section
.mdc-dialog__surface {
    border-radius: 0 !important;
}

//dialog section ends

//styling for text

.fnbo-text-indent {
    text-indent: 40px;
}

.font-text-weight-4 {
    font-weight: 400 !important;
}

.font-text-weight-5 {
    font-weight: 500 !important;
}

.font-text-weight-6 {
    font-weight: 600 !important;
    letter-spacing: 0.1em !important;
}

.fnbo-text-right {
    text-align: right;
}

.fnbo-text-italic {
    font-style: italic;
}

.fnbo-text,
.fnbo-text-body {
    font-size: var(--default-font-size);
}

.fnbo-text-body {
    letter-spacing: -0.016em;
    line-height: 2;
    font-style: normal;
}


.fnbo-text-sub-heading {
    font-size: var(--secondary-header-font-size);
    letter-spacing: 0.15em;
    line-height: 1.7;
}

.fnbo-text-heading {
    font-size: var(--primary-header-font-size);
    line-height: 2;
    letter-spacing: 0.17em;
    text-transform: uppercase;
}

.fnbo-text-secondary {
    font-size: var(--tertiary-font-size);
    color: #808080 !important;
}

#fnbo-cart-right-area * .fnbo-cart-text {
    color: #000;
    font-size: var(--secondary-font-size);
}

.fnbo-cart-headings {
    margin-bottom: 0;
    font-size: var(--header-font-size);
    font-weight: 600;
    letter-spacing: 0.15em;
    line-height: 3.5;
}

.mat-grid-title-content {
    font-size: 25px;
    font-weight: 600;
    justify-content: left !important;
}

.fnbo-error-msg {
    color: red;
}

.fnbo-error-msg.fnbo-error-msg-bg {

    background-color: #e1bebe;
    border: none;
    text-align: center;
    border-radius: 0;
    font-size: var(--secondary-font-size);
}

.fnbo-error-msg.fnbo-text-sm {
    font-size: var(--tertiary-font-size);

}

//styling for text ends

// iframe for payment styling
#payment_information {
    width: 100% !important;
}

// iframe for payment styling

//general styling
.fnbo-hide {
    display: none !important;
}

.fnbo-img-w100 {
    width: 100%;
}

//general styling ends

//mat drop down list text style
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: inherit !important;
}

//ends

//mat-badge
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    background-color: #000;
}

//ends

// loading spinner styles

.fnbo-overlay-spinner {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 100%;
    width: 100%;
    z-index: 99999;
    margin: 0;
}

.fnbo-loading-area {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.loading-spinner-btn {
    position: relative;
}

.loading-spinner-btn::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1.4s ease infinite;
}

// Banner Overlay HTML Styling
.cover-banner-text h2 {
       font-size: 48px;
       width: 70%;
       line-height: 1.6;
}

@media screen and (max-width: 767px) {
    .cover-banner-text h2 {
    position: absolute;
    font-size: xx-large;
    width: 80%;
    left: -12%;
    }
}

@media screen and (max-width: 600px) {
    .cover-banner-text h2 {
        position: absolute;
        font-size: x-large;
        width: 80%;
        left: -8%;
    }
}


@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

//default color
.mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #000;
}


//ends

.fnbo-terms-with-products .uniterms-dyn-area {
    display: none !important;
}

@media (min-width: 450px) {
    .PageMain {
        width: 100%;
    }
}

// iframe for payment styling ends

/* Small devices (portrait tablets and large phones, 768px and below) */
@media only screen and (min-width: 768px) and (max-width:1024px) {
    .fnbo-center-content {
        margin-left: 50px;
        margin-right: 50px;
    }
}


/* Small devices (portrait tablets and large phones, 768px and below) */
@media only screen and (max-width: 768px) {

    .fnbo-center-content {
        margin-left: 10px;
        margin-right: 10px;
    }

    #fnbo-search-dialog-area * .mat-mdc-form-field-icon-prefix>.mat-icon {
        font-size: 20px;
        padding: 5px;
        height: 20px;
        width: 20px;
    }

    .mdc-list-item__primary-text {
        font-size: var(--secondary-font-size);
    }


    .fnbo-custrwds-panel,
    .fnbo-fca-r-panel {
        margin-left: 10px;
        margin-right: 10px;
    }


    .fnbo-sr-filter-items * .mat-mdc-form-field-infix,
    .fnbo-products-filter-items * .mat-mdc-form-field-infix {
        min-height: 40px !important;
        padding-top: 14px !important;
        padding-bottom: 5px !important;
        width: 100px !important;
    }

    .fnbo-products-filter-items * .mat-mdc-floating-label,
    .fnbo-sr-filter-items * .mat-mdc-floating-label {
        top: 22px !important;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .mobile-hidden {
        display: none !important;
    }

    .tablet-hidden {}

    .web-hidden {
        display: block !important;
    }
}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .mobile-hidden {
        display: none !important;
    }

    .web-hidden {
        display: block !important;
    }

    .tablet-hidden {
        display: none !important;
    }
}

/* Media Query for Tablets Ipads portrait mode at max width of 1024px */
@media only screen and (max-width: 1024px) {

    .fnbo-sr-filter-items * .mdc-text-field--filled,
    .fnbo-products-filter-items * .mdc-text-field--filled {
        border: 1px solid;
    }
}



@media only screen and (min-height : 1024px) {
    .fnbo-content {
        min-height: 800px !important;
    }
}


@media only screen and (min-height : 1300px) {
    .fnbo-content {
        min-height: 840px !important;
    }
}