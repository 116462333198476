@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */
body {
    width: 100%;
    margin: 0;
}

html,
body {
    height: 100%;
    color: #000;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

a{
    cursor: pointer;
}

ngb-carousel {
    width: 100%;
}

ngb-carousel img {
    width: 100%;
    outline: none;
    height: 500px;
    max-height: 100%;
}

ngb-carousel {
    width: inherit;
    height: inherit;
}

.carousel-inner {
    overflow: visible;
}

.carousel-item {
    display: flex !important;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s cubic-bezier(0.4, 0, 1, 1);
    z-index: -1;
    justify-content: center;
    max-height: 500px;

}

.carousel-item.active {
    opacity: 1;
    visibility: visible;
    z-index: 10;
}

.carousel-control-prev {
    z-index: 20;
}

.carousel-control-next {
    z-index: 20;
}

.carousel-indicators {
    z-index: 20;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: #000;
    border-radius: 15px;

}

.carousel-control-next-icon {
    background-size: 20px;
    background-image: url("assets/icons/carousel-forward-arrow.svg");
}

.carousel-control-prev-icon {
    background-size: 12px;
    background-image: url("assets/icons/carousel-back-arrow.svg");

}

.fnbo-carosuel-mb * .carousel-control-prev-icon {
    background-size: 6px !important;
    width: 1rem !important;
    height: 1rem !important;
}

.fnbo-carosuel-mb * .carousel-control-next-icon {
    background-size: 10px !important;
    width: 1rem !important;
    height: 1rem !important;
}

@media only screen and (max-width: 600px) {

    ngb-carousel img {
        min-height: 250px;
        max-height: 250px;
    }

    .fnbo-banner-img{
        width: 100%;
    }
}