// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$FBOWeb-primary: mat.define-palette(mat.$indigo-palette);
$FBOWeb-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$FBOWeb-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$FBOWeb-theme: mat.define-light-theme((color: (primary: $FBOWeb-primary,
        accent: $FBOWeb-accent,
        warn: $FBOWeb-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($FBOWeb-theme);

:root {
  //color
  --grey-text : #b3b3b3;

  // it is recommended to go up by 2 each level
  --default-font-size: 16px; 
  --xlarge-font-size: calc(var(--default-font-size) + 8px);
  --large-font-size: calc(var(--default-font-size) - 6px);
  --header-font-size: calc(var(--default-font-size) + 4px);
  --sub-header-font-size: calc(var(--default-font-size) + 2px);
  
  --secondary-font-size: calc(var(--default-font-size) - 2px);
  --tertiary-font-size: calc(var(--default-font-size) - 4px);
  --xxs-font-size: 10px;

  --primary-header-font-size: calc(var(--default-font-size) * 1.8);
  --secondary-header-font-size: calc(var(--default-font-size) * 1.4);


  --primary-header-letter-space: 0.173em;
  --secondary-header-letter-space: 0.15em;



  /* Small devices (portrait tablets and large phones, 600px and below) */
  @media only screen and (max-width: 600px) {
    --default-font-size: 14px;
    --primary-header-font-size: calc(var(--default-font-size) * 1.6);
    --secondary-header-font-size: calc(var(--default-font-size) * 1.2);
  }

  .fnbo-silver{
    --default-font-size: 20px !important; 
  }
}